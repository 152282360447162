
@import "~bootstrap/scss/bootstrap";

body {
    background-color: #F7F7F7;
    padding: 30px 0;
}

.app-container {
    padding: 30px;
    background: #fff;
    box-shadow: 4px 4px 10px rgba(#000, .1);
}
